<template>
    <div class="animate__animated animate__fadeIn">
        <loading-circle v-if="loading"></loading-circle>

        <v-form v-model="valid" ref="r_form_edit_profile" v-if="!loading">
            <v-tabs
                    :vertical="$root.windowWidth >= 800"
                    :color="$variables.v.theme.secondary">
                <vertical-tabs v-for="navpoint in navigation" :key="navpoint.id" :navpoint="navpoint"></vertical-tabs>

                <v-col v-show="$root.windowWidth >= 800" class="text-right">
                    <v-btn :disabled="!valid"
                           depressed
                           :color="$variables.v.theme.primary"
                           @click="apply_profile">{{$t('profile.edit.save')}}
                    </v-btn>
                </v-col>


                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <v-text-field v-model="user.nickname"
                                          :rules="[rules.max30]"
                                          :label="$t('profile.edit.nickname')"
                                          :color="$variables.v.theme.secondary"
                                          maxlength="30"
                                          class="default-textfield-width"
                                          counter></v-text-field>

                            <v-select v-model="user.gender"
                                      :label="$t('profile.edit.gender')"
                                      :color="$variables.v.theme.secondary"
                                      :items="$variables.v.genderlist"
                                      item-value="text"
                                      dense
                                      class="default-textfield-width"
                                      outlined>
                                <template slot=selection slot-scope="data">
                                    {{$t(data.item.text)}}
                                </template>
                                <template slot=item slot-scope="data">
                                    {{$t(data.item.text)}}
                                </template>
                            </v-select>


                            <v-select v-model="user.country"
                                      :label="$t('profile.edit.country')"
                                      :color="$variables.v.theme.secondary"
                                      :items="$variables.s.countryListAllIsoData"
                                      item-value="code"
                                      item-text="name"
                                      dense
                                      class="default-textfield-width"
                                      outlined></v-select>

                            <v-textarea v-model="user.description"
                                        :rules="[rules.max500, c_max5rows]"
                                        :label="$t('profile.edit.description')"
                                        maxlength="500"
                                        :color="$variables.v.theme.secondary"
                                        counter
                                        outlined
                                        auto-grow
                                        no-resize></v-textarea>
                            <!--                                    TODO BADGE AUSWAHL-->
                        </v-card-text>
                    </v-card>
                </v-tab-item>


                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <div>
                                <div class="font-weight-bold" v-html="$t('profile.edit.picture-info')"></div>
                                <div class="d-flex text-center font-weight-bold mb-10">
                                    <div class="d-flex">
                                        <div class="mx-5">
                                            <p class="my-5">{{$t('profile.edit.current-avatar')}}</p>
                                            <v-avatar tile size="150">
                                                <img :src="user_backup.avatar_url"
                                                     alt=""
                                                     loading="lazy">
                                            </v-avatar>
                                        </div>
                                        <div class="mx-5">
                                            <p class="my-5">{{$t('profile.edit.next-avatar')}}</p>
                                            <v-avatar tile size="150">
                                                <img :src="user.avatar_url"
                                                     alt=""
                                                     loading="lazy">
                                            </v-avatar>
                                        </div>

                                        <div class="d-flex">
                                            <div class="mx-5">
                                                <p class="my-5">{{$t('profile.edit.current-background')}}</p>
                                                <v-avatar tile size="150">
                                                    <img :src="user_backup.background_url"
                                                         alt=""
                                                         loading="lazy">
                                                </v-avatar>
                                            </div>
                                            <div class="mx-5">
                                                <p class="my-5">{{$t('profile.edit.next-background')}}</p>
                                                <v-avatar tile size="150">
                                                    <img :src="user.background_url"
                                                         alt=""
                                                         loading="lazy">
                                                </v-avatar>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-text-field v-model="user.avatar_url"
                                              :label="$t('profile.edit.avatar-url')"
                                              prepend-icon="mdi-camera"
                                              :color="$variables.v.theme.secondary"
                                              class="default-textfield-width"
                                              counter></v-text-field>

                                <v-text-field v-model="user.background_url"
                                              :label="$t('profile.edit.background-url')"
                                              prepend-icon="mdi-camera"
                                              :color="$variables.v.theme.secondary"
                                              class="default-textfield-width mt-5"
                                              counter></v-text-field>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>


                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <div v-for="(c, index) in user.contact" :key="index">
                                <div class="d-flex">
                                    <v-text-field v-model="c.description"
                                                  :label="$t('profile.edit.contact-description')"
                                                  :color="$variables.v.theme.secondary"
                                                  style="max-width:300px"
                                                  class="mr-5"
                                                  maxlength="20"
                                                  counter
                                                  :rules="[rules.required, rules.min3, rules.max100]"></v-text-field>
                                    <v-text-field v-model="c.text"
                                                  :label="$t('profile.edit.contact-text')"
                                                  :color="$variables.v.theme.secondary"
                                                  style="max-width:300px"
                                                  class="mr-5"
                                                  maxlength="100"
                                                  counter
                                                  :rules="[rules.required, rules.min3, rules.max100]"></v-text-field>
                                    <v-col>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs"
                                                        v-on="on"
                                                        @click="user.contact.splice(index,1)">
                                                    mdi-trash-can-outline
                                                </v-icon>
                                            </template>
                                            <span>{{$t('profile.edit.remove-row')}}</span>
                                        </v-tooltip>
                                    </v-col>
                                </div>
                                <v-divider class="d-sm-none"></v-divider>
                            </div>

                        </v-card-text>

                        <v-tooltip bottom v-if="user.contact.length < $variables.v.profile_max_contact_rows">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs"
                                        v-on="on"
                                        class="ml-5 mb-3"
                                        @click="insertContactRow">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <span>{{$t('profile.edit.insert-row')}}</span>
                        </v-tooltip>

                    </v-card>
                </v-tab-item>
            </v-tabs>

            <v-col v-show="$root.windowWidth <= 800" class="text-center">
                <v-btn :disabled="!valid"
                       depressed
                       :color="$variables.v.theme.primary"
                       @click="apply_profile">{{$t('profile.edit.save')}}
                </v-btn>
            </v-col>
        </v-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                navigation : [
                    {id: 1, icon: "mdi-information-outline", text: "profile.edit.nav.header"},
                    {id: 2, icon: "mdi-account", text: "profile.edit.nav.personalization"},
                    {id: 3, icon: "mdi-email", text: "profile.edit.nav.contact"}
                ],
                valid      : false,
                loading    : false,
                user       : {},
                user_backup: {},

                rules: {
                    required       : v => (!!v) || this.$t("rules.required"),
                    min3           : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    max30          : v => (v !== undefined && v.length <= 30) || this.$t("rules.max-30-chars"),
                    max100         : v => (v !== undefined && v.length <= 100) || this.$t("rules.max-100-chars"),
                    max500         : v => (v !== undefined && v.length <= 500) || this.$t("rules.max-500-chars"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                }
            }
        },
        created() {
            this.init();
        },
        methods : {
            async init() {
                this.loading = true;
                this.get_profile();
            },
            async get_profile() {
                if (this.$user.user.auth) {
                    let response = await this.func.ax("/php/getter.php", {
                        func           : "get_profile",
                        user_id_or_name: this.$route.params.profileid ? this.$route.params.profileid : this.$user.user.id,
                    });
                    if (this.func.isObject(response)) {
                        this.user        = JSON.parse(JSON.stringify(response));
                        this.user_backup = JSON.parse(JSON.stringify(response));
                    }
                }
                else
                    await this.$router.push(this.$variables.v.navigation.default);
                this.loading = false;
            },
            async apply_profile() {
                this.loading = true;

                if (this.$refs.r_form_edit_profile.validate()) {
                    let data     = {
                        func   : "update_profile",
                        userid : this.$user.user.id,
                        token  : this.$user.user.token,
                        profile: this.user,
                    };
                    let response = await this.func.ax("/php/update_user.php", data);

                    await this.$root.check_response(response, async () => {
                        this.eventbus.$emit("SB", "profile.edit.msg.updated-user-profile", "success", 5);
                        // Get profileid from params else from the user object
                        let profileid = this.$route.params.profileid ? this.$route.params.profileid : this.$user.user.id;
                        // Open affected profile
                        await this.$router.push(this.$variables.v.navigation.profile_id + profileid);
                    }, "profile.edit.msg.error-update-user-profile");
                }
                this.loading = false;
            },
            insertContactRow() {
                // Add a line to the contact object
                if (this.user.contact.length < this.$variables.v.profile_max_contact_rows)
                    this.user.contact.push({description: "", text: ""});
            }
        },
        computed: {
            c_max5rows() {
                return () => this.user.description.split(/\r\n|\r|\n/).length <= 5 || this.$t("rules.description-only-5-rows");
            }
        },
        beforeRouteEnter(to, from, next) { // Prevent not signed in user from access this site
            next(vm => {
                if (vm.$user.user.auth === false)
                    next({path: vm.$variables.v.navigation.default});
                if (vm.$route.params.profileid && !vm.$root.check_mod())
                    next({path: vm.$variables.v.navigation.page404});
            })
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-profile-edit')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>